<template>
    <b-card class="card-designs py-0">
        <div class="row d-flex justify-content-between">
            <b-card-body class="py-0 pl-1">
                <h2 class="fs-1p5rem">
                    <strong v-if="counter !== null">
                        {{ counter }}
                    </strong>
                    <small v-else class="text-muted">
                        (Not Available)
                    </small>
                </h2>
            </b-card-body>
            <div>
                <b-img fluid :src="img" />
            </div>
        </div>
        <b-card-header class="py-0 mt-1 pl-0">
            <b-card-title class="text-uppercase fs-1rem">
                {{ label }}
            </b-card-title>
        </b-card-header>
    </b-card>
</template>

<script>
export default {
    props: {
        counter: Number|String|null,
        img: String|null,
        label: String|null,
    }
}
</script>

<style></style>