<template>
  <div class="shop-dashboard shop-analytics">
    <div class="row">
      <div class="col-12 col-md-3">
        <HeaderCard 
          :counter="storeDashboard.received_data"
          :img="`${$apiUrl}/images/icons/order_received.png`"
          label="ORDERS RECEIVED"
        />
      </div>
      <div class="col-12 col-md-3">
        <HeaderCard 
          :counter="storeDashboard.delivered_data"
          :img="`${$apiUrl}/images/icons/order_completed.png`"
          label="ORDERS COMPLETED"
        />
      </div>
      <div class="col-12 col-md-3">
        <HeaderCard 
          :counter="`${(storeDashboard.today_earnings && storeDashboard.today_earnings.total_amount) ? storeDashboard.today_earnings.total_amount : 0} ${storeDashboard.currency}`"
          :img="`${$apiUrl}/images/icons/today_earning.png`"
          label="TODAY EARNINGS"
        />
      </div>
      <div class="col-12 col-md-3">
        <HeaderCard 
          :counter="`${(storeDashboard.total_earnings && storeDashboard.total_earnings.total_amount) ? storeDashboard.total_earnings.total_amount : 0} ${storeDashboard.currency}`"
          :img="`${$apiUrl}/images/icons/total_earnings.png`"
          label="TOTAL EARNINGS"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mt-0">
        <div class="row mb-2">
          <b-col cols="12" md="4">
            <b-form-group :label="$t('From Date')">
              <b-form-datepicker v-model="dataInfo.fromDate" locale="en" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group :label="$t('To Date')">
              <b-form-datepicker v-model="dataInfo.toDate" locale="en" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4" class="my-auto">
            <b-button variant="primary" @click="getStoreDashboard">
              {{ $t('Search') }}
            </b-button>
          </b-col>
        </div>
        <highcharts class="reset-zoom" :options="chartOptions" />
      </div>
      <div class="col-md-12 mt-3">
        <b-card>
          <h2>{{ $t('Recent Orders') }}</h2>
          <good-data-table ref="recent_orders" :mode="'local'" :no-serial="true" :columns="columns" :load-local-data="true" />
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BCardTitle, BCol, BFormGroup, BFormDatepicker, BButton } from 'bootstrap-vue'
import { hideLoader, showErrorNotification, showLoader } from '@/@core/comp-functions/ui/app'
import { Chart } from 'highcharts-vue'
import moment from 'moment'
import { useStoreUi } from './useStore'
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import { isEmpty } from '@/utilities'
import HeaderCard from '@/components/common/HeaderCard.vue';

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    highcharts: Chart,
    BCol,
    BFormGroup,
    BFormDatepicker,
    BButton,
    GoodDataTable,
    HeaderCard,
  },
  data() {
    return {
      storeDashboard: {},
      dataInfo: {
        fromDate: '',
        toDate: '',
      },
      chartOptions: {
        series: [],
        title: '',
        xAxis: {
          categories: [],
        },
        credits: {
          enabled: false,
        },
        yAxis: {
          title: {
            text: 'No. of Orders',
          },
        },
      },
    }
  },
  computed: {
    storeInfo() {
      return window.SuperApp.getters.userInfo()
    },
    columns: {
      get() {
        return [
          {
            field: 'store_order_invoice_id',
            label: 'Order ID',
            sortable: false,
          },
          {
            label: 'Customer',
            field: 'customer_name',
            useResolver: true,
            useHtml: true,
            renderer: props => (!isEmpty(props.user) ? `${props.user.first_name} ${props.user.last_name}` : null),
          },
          {
            field: 'created_time',
            label: 'Ordered On',
            sortable: false,
          },
          {
            field: 'status',
            label: 'Status',
            sortable: false,
          },
          {
            label: 'Amount',
            field: 'amount',
            useResolver: true,
            useHtml: true,
            renderer: props => (!isEmpty(props.order_invoice) ? this.storeDashboard.currency + props.order_invoice.total_amount : this.storeDashboard.currency + 0.0),
          },
        ]
      },
    },
  },
  mounted() {
    this.getStoreDashboard()
  },
  methods: {
    getStoreDashboard() {
      showLoader()
      const { getStoreOrders } = useStoreUi()
      const dataPayload = []
      if (this.dataInfo.fromDate) {
        dataPayload.push(`date_from=${moment(this.dataInfo.fromDate).format('YYYY-MM-DD')}`)
      }
      if (this.dataInfo.toDate) {
        dataPayload.push(`date_to=${moment(this.dataInfo.toDate).format('YYYY-MM-DD')}`)
      }
      getStoreOrders(dataPayload.length > 0 ? `?${dataPayload.join('&')}` : '')
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.storeDashboard = data.responseData
            this.chartOptions.xAxis.categories = new Array(12).fill(0).map((_, i) => moment(new Date(new Date().getFullYear(), i, 1)).format('MMMM'))
            this.chartOptions.series = [
              {
                name: 'Completed Orders',
                data: this.storeDashboard.completed_data,
              },
              {
                name: 'Cancelled Orders',
                data: this.storeDashboard.cancelled_data,
              },
            ]
          }
          if (!isEmpty(this.storeDashboard.recent_data)) {
            this.$refs.recent_orders.setLocalData(this.storeDashboard.recent_data)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style lang="scss">
.shop-analytics {
  .b-form-datepicker {
    .dropdown-menu {
      z-index: 999;
    }
  }
}
</style>
